import * as React from "react"

import {Link, useStaticQuery, graphql} from 'gatsby'
import {StaticImage} from 'gatsby-plugin-image'

import Layout from '../components/layout/layout'
import Blogpost from "../components/blogpost/blogpost"

import hero_image from "../images/banners/hero.jpg"
import parse from "html-react-parser";
import Adsense from "../components/adsense/adsense";

const pageTitle = "Welcome to VR2WOA"

const IndexPage = () => {
    const query = graphql`
      {
        restApiBlogApi {
          objects {
            article_type
            content_en
            summary_en
            title_en
            slug
            mode_type
            id
            flag_online
            creation_date_timestamp
            creation_date
          }
        }
      }`

    const objects = useStaticQuery(query)

    return (
        <Layout pageTitle={pageTitle}>
            {/*<StaticImage src="../images/banners/hero.jpg" alt="" objectFit="cover"/>*/}
            {/*<pre>{JSON.stringify(objects.restApiBlogApi.objects.slice(0, 2), null, 4)}</pre>*/}
            <Adsense client="ca-pub-6505108883414662" slot="2219379165" format="auto"/>

            <div className="heroDiv">
                <div className="heroMessageFlex">
                    <div className="heroMessageAlpha">
                        <h2>Welcome to VR2WOA.com</h2>

                        <p>I have got my Hong Kong amateur radio license at June 2014, but started operating on HF on
                            September
                            2015. And yea, I made my first ever QSL contact on September 20, 2015. So please forgive me
                            if I
                            speak in-proficiently over the air.</p>
                    </div>
                </div>
                <img src={hero_image} className="img-fluid heroImage"/>
            </div>

            <div className="container">
                <div className="row iconsRow">
                    <div className="col-3">
                        <Link to="/blog/">
                            <svg className="svg-inline--fa fa-home fa-w-18 fa-4x" aria-hidden="true" data-prefix="fas"
                                 data-icon="home" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"
                                 data-fa-i2svg="">
                                <path fill="currentColor"
                                      d="M488 312.7V456c0 13.3-10.7 24-24 24H348c-6.6 0-12-5.4-12-12V356c0-6.6-5.4-12-12-12h-72c-6.6 0-12 5.4-12 12v112c0 6.6-5.4 12-12 12H112c-13.3 0-24-10.7-24-24V312.7c0-3.6 1.6-7 4.4-9.3l188-154.8c4.4-3.6 10.8-3.6 15.3 0l188 154.8c2.7 2.3 4.3 5.7 4.3 9.3zm83.6-60.9L488 182.9V44.4c0-6.6-5.4-12-12-12h-56c-6.6 0-12 5.4-12 12V117l-89.5-73.7c-17.7-14.6-43.3-14.6-61 0L4.4 251.8c-5.1 4.2-5.8 11.8-1.6 16.9l25.5 31c4.2 5.1 11.8 5.8 16.9 1.6l235.2-193.7c4.4-3.6 10.8-3.6 15.3 0l235.2 193.7c5.1 4.2 12.7 3.5 16.9-1.6l25.5-31c4.2-5.2 3.4-12.7-1.7-16.9z"></path>
                            </svg>

                            <p>VR2WOA Blog</p>
                        </Link>
                    </div>
                    <div className="col-3">
                        <a href="/en/pages/relays/">
                            <svg className="svg-inline--fa fa-flag fa-w-16 fa-4x" aria-hidden="true" data-prefix="fas"
                                 data-icon="flag" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                                 data-fa-i2svg="">
                                <path fill="currentColor"
                                      d="M349.565 98.783C295.978 98.783 251.721 64 184.348 64c-24.955 0-47.309 4.384-68.045 12.013a55.947 55.947 0 0 0 3.586-23.562C118.117 24.015 94.806 1.206 66.338.048 34.345-1.254 8 24.296 8 56c0 19.026 9.497 35.825 24 45.945V488c0 13.255 10.745 24 24 24h16c13.255 0 24-10.745 24-24v-94.4c28.311-12.064 63.582-22.122 114.435-22.122 53.588 0 97.844 34.783 165.217 34.783 48.169 0 86.667-16.294 122.505-40.858C506.84 359.452 512 349.571 512 339.045v-243.1c0-23.393-24.269-38.87-45.485-29.016-34.338 15.948-76.454 31.854-116.95 31.854z"></path>
                            </svg>

                            <p>Hong Kong Repeaters</p></a>
                    </div>
                    <div className="col-3">
                        <a href="/en/pages/frequencies/">
                            <svg className="svg-inline--fa fa-list-alt fa-w-16 fa-4x" aria-hidden="true"
                                 data-prefix="far" data-icon="list-alt" role="img" xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 512 512" data-fa-i2svg="">
                                <path fill="currentColor"
                                      d="M464 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zm-6 400H54a6 6 0 0 1-6-6V86a6 6 0 0 1 6-6h404a6 6 0 0 1 6 6v340a6 6 0 0 1-6 6zm-42-92v24c0 6.627-5.373 12-12 12H204c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h200c6.627 0 12 5.373 12 12zm0-96v24c0 6.627-5.373 12-12 12H204c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h200c6.627 0 12 5.373 12 12zm0-96v24c0 6.627-5.373 12-12 12H204c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h200c6.627 0 12 5.373 12 12zm-252 12c0 19.882-16.118 36-36 36s-36-16.118-36-36 16.118-36 36-36 36 16.118 36 36zm0 96c0 19.882-16.118 36-36 36s-36-16.118-36-36 16.118-36 36-36 36 16.118 36 36zm0 96c0 19.882-16.118 36-36 36s-36-16.118-36-36 16.118-36 36-36 36 16.118 36 36z"></path>
                            </svg>


                            <p>Radio Frequencies</p></a>
                    </div>
                    <div className="col-3">
                        <a href="/en/pages/bands/">
                            <svg className="svg-inline--fa fa-broadcast-tower fa-w-20 fa-4x" aria-hidden="true"
                                 data-prefix="fas" data-icon="broadcast-tower" role="img"
                                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" data-fa-i2svg="">
                                <path fill="currentColor"
                                      d="M150.94 192h33.73c11.01 0 18.61-10.83 14.86-21.18-4.93-13.58-7.55-27.98-7.55-42.82s2.62-29.24 7.55-42.82C203.29 74.83 195.68 64 184.67 64h-33.73c-7.01 0-13.46 4.49-15.41 11.23C130.64 92.21 128 109.88 128 128c0 18.12 2.64 35.79 7.54 52.76 1.94 6.74 8.39 11.24 15.4 11.24zM89.92 23.34C95.56 12.72 87.97 0 75.96 0H40.63c-6.27 0-12.14 3.59-14.74 9.31C9.4 45.54 0 85.65 0 128c0 24.75 3.12 68.33 26.69 118.86 2.62 5.63 8.42 9.14 14.61 9.14h34.84c12.02 0 19.61-12.74 13.95-23.37-49.78-93.32-16.71-178.15-.17-209.29zM614.06 9.29C611.46 3.58 605.6 0 599.33 0h-35.42c-11.98 0-19.66 12.66-14.02 23.25 18.27 34.29 48.42 119.42.28 209.23-5.72 10.68 1.8 23.52 13.91 23.52h35.23c6.27 0 12.13-3.58 14.73-9.29C630.57 210.48 640 170.36 640 128s-9.42-82.48-25.94-118.71zM489.06 64h-33.73c-11.01 0-18.61 10.83-14.86 21.18 4.93 13.58 7.55 27.98 7.55 42.82s-2.62 29.24-7.55 42.82c-3.76 10.35 3.85 21.18 14.86 21.18h33.73c7.02 0 13.46-4.49 15.41-11.24 4.9-16.97 7.53-34.64 7.53-52.76 0-18.12-2.64-35.79-7.54-52.76-1.94-6.75-8.39-11.24-15.4-11.24zm-116.3 100.12c7.05-10.29 11.2-22.71 11.2-36.12 0-35.35-28.63-64-63.96-64-35.32 0-63.96 28.65-63.96 64 0 13.41 4.15 25.83 11.2 36.12l-130.5 313.41c-3.4 8.15.46 17.52 8.61 20.92l29.51 12.31c8.15 3.4 17.52-.46 20.91-8.61L244.96 384h150.07l49.2 118.15c3.4 8.16 12.76 12.01 20.91 8.61l29.51-12.31c8.15-3.4 12-12.77 8.61-20.92l-130.5-313.41zM271.62 320L320 203.81 368.38 320h-96.76z"></path>
                            </svg>
                            <p>Radio Bands</p></a></div>

                </div>

            </div>

            <hr/>


            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h2 className="mb-3">New Blog Posts</h2>
                    </div>
                    <testcomponent/>

                    {objects && objects.restApiBlogApi && objects.restApiBlogApi.objects ? objects.restApiBlogApi.objects.slice(0, 2).map(post => (

                        <div className="col-md-6 col-12">
                            <Blogpost post={post} noBorder="true"/>

                            <testcomponent/>
                        </div>

                    )) : null}


                </div>

            </div>

        </Layout>

    )
}

export default IndexPage
