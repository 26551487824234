import React, {useState} from 'react';
import parse from "html-react-parser";
import {Link} from 'gatsby'
import Adsense from "../adsense/adsense";

const blogpost = ({post, noBorder}) => {
    return (
        <div
            key={post.id} className={ noBorder ? "blog-noborder" : "blog"}
        >
                <Adsense client="ca-pub-6505108883414662" slot="2219379165" format="auto" />

            <h3><Link to={`/blog/${post.slug}/`}>{post.title_en}</Link></h3>
                                    <img src={post.thumbnail_url} className="blogThumbnail" />

            <p>{ (new Date(post.creation_date_timestamp * 1000)).toLocaleDateString() }</p>

            <p>{parse(post.summary_en)}</p>


            <a className="btn btn-sm btn-danger mb-4" href="/blog/diy-vhf-jpole-antenna/">More...</a>
        </div>
    )
}

export default blogpost;